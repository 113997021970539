import "index.css"
import { Application } from "@hotwired/stimulus"
import Dropdown from 'stimulus-dropdown'
import Lightbox from 'stimulus-lightbox'

const application = Application.start()
application.register('dropdown', Dropdown)
application.register('lightbox', Lightbox)

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

// Import lightbox css
import 'lightgallery/css/lightgallery.css'

console.info("Bridgetown is loaded!")

window.Stimulus = Application.start()

import controllers from "./controllers/**/*.{js,js.rb}"
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename.replace("./controllers/", "")
      .replace(/[_-]controller..*$/, "")
      .replace("_", "-")
      .replace("/", "--")

    Stimulus.register(identifier, controller.default)
  }
})
